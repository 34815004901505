import request from '@/utils/request'

/**
 * 首页内容
 * @param {Object} data
 */
export function homeContent(data) {
  return request({
    url: 'CMS/Index',
    method: 'post',
    data
  })
}

/**
 * 公司介绍
 * @param {Object} data
 */
export function companyContent(data) {
  return request({
    url: 'CMS/Company',
    method: 'post',
    data
  })
}

/**
 * 解决方案列表
 * @param {Object} data
 */
export function solutionList(data) {
  return request({
    url: 'CMS_Solution/List',
    method: 'post',
    data
  })
}

/**
 * 解决方案详情
 * @param {Object} data
 */
export function solutionInfo(data) {
  return request({
    url: 'CMS_Solution/Info',
    method: 'post',
    data
  })
}

/**
 *  产品线
 * @param {Object} data 
 */
export function productLine(data) {
  return request({
    url: 'CMS_Product/Line',
    method: 'post',
    data
  })
}

/**
 *  产品列表
 * @param {Object} data
 */
export function productList(data) {
  return request({
    url: 'CMS_Product/List',
    method: 'post',
    data
  })
}

/**
 *  产品详情
 * @param {Object} data
 */
export function productInfo(data) {
  return request({
    url: 'CMS_Product/Info',
    method: 'post',
    data
  })
}

/**
 *  属性内容
 * @param {Object} data
 */
export function AttributeContent(data) {
  return request({
    url: 'CMS/Property',
    method: 'post',
    data
  })
}
/**
 *  新闻列表
 * @param {Object} data
 */
export function NewsList(data) {
  return request({
    url: 'CMS_News/List',
    method: 'post',
    data
  })
}

/**
 *  新闻详情
 * @param {Object} data
 */
export function NewsDetail(data) {
  return request({
    url: 'CMS_News/Info',
    method: 'post',
    data
  })
}
/**
 *  支持与下载列表
 * @param {Object} data
 */
export function supportList(data) {
  return request({
    url: 'CMS_Support/List',
    method: 'post',
    data
  })
}
/**
 *  支持与下载列表
 * @param {Object} data
 */
export function supportDownload(data) {
  return request({
    url: 'CMS_Support/Info',
    method: 'post',
    data
  })
}
// /**
//  * 首页内容
//  * @param {Object} params
//  */
//  export function homeContent (params) {
//   return request({
//     url:'CMS/Index',
//     method: 'get',
//     params
//   })
// }
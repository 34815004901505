<template>
  <div id="app" v-if="showData">
    <publicHeader :skipIndex="skipIndex"></publicHeader>
    <router-view />
    <publicBottom></publicBottom>
  </div>
</template>

<script>
import { mapState } from "vuex";
import publicHeader from "@/components/publicHeader.vue";
import publicBottom from "@/components/publicBottom.vue";
export default {
  name: "solve",
  components: {
    publicHeader,
    publicBottom,
  },
  data() {
    return {
      showData: false,
    };
  },
  mounted() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.showData = false;
      window.location.replace("/mobile");
      // window.location.href = "http://www.jiyou-tech.com/2020/ceshi555";
    } else {
      this.showData = true;
    }
  },
  computed: {
    ...mapState({
      skipIndex: (state) => state.changeLiBackGround,
    }),
  },
  methods: {},
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-end {
  justify-content: flex-end;
}

video::-webkit-media-controls-timeline {
  background: transparent;
}
</style>

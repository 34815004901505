<template>
  <div class="produts">
    <div style="min-width: 1400px">
      <img class="pageCore" src="../assets/productTopIMG.jpg" alt />
    </div>
    <div class="subnav">
      <div class="subnavDiv">
        <!-- <div class="menuCont">首页 > 产品中心{{clickNum==null?'':' > '+detailsData[clickNum].title}}</div> -->
        <p class="subnavDivText"><a href="" style="text-decoration: none; color: inherit;">{{ $t('support.Home') }}</a>
        </p>
        <p class="subnavDivText">> {{ $t('product.ProductCenter') }}</p>
        <p class="subnavDivText" style="margin-left: 10px">{{ clickIndex == null ? '' : ' > ' +
          longMenuArr[clickIndex]?.Line }}</p>
        <!-- <p class="subnavDivText">{{ type | dataFormat }}</p> -->
      </div>
    </div>
    <div class="main" ref="box">
      <div class="leftNav">
        <leftMenu :menuType="menuType" :clickIndex="clickIndex" :longMenuArr="longMenuArr" @activeType="changetype">
        </leftMenu>
      </div>
      <div class="content" v-if="flag">
        <div class="productDiv" v-for="(item, index) in filterProduct" :key="index">
          <div class="productDivImg">
            <img class="productDivPic" :src="item.Headimg" alt />
          </div>
          <div class="productTitle">{{ item.Product }}</div>
          <div class="productLine"></div>
          <div class="productType">{{ item.Model }}</div>
          <!-- <div class="productText">{{ item.Outline }}</div> -->
          <div class="productDetail" @click="entryDetail(item.ProductID, index)">{{ $t('product.ViewDetails') }}</div>
        </div>
      </div>
      <!-- <div class="detail" v-if="!flag && productDetail"> -->
      <div class="detail" v-if="!flag && productDetail">
        <div class="flex">
          <div class="product-img">
            <video v-if="productDetail.Product[0].VideoUrls" controls :poster="productDetail.Product[0].PicUrls"
              :src="productDetail.Product[0].VideoUrls"></video>
            <img v-else :src="productDetail.Product[0].PicUrls" />
          </div>
          <div class="flex flex-column jc-space-between" style="height: inherit; margin-left: 40px">
            <div>
              <p class="product-title">{{ productDetail.Product[0].Product }}</p>
              <p class="product-gap"></p>
              <p class="product-model">{{ productDetail.Product[0].Model }}</p>
              <p class="product-outline">{{ productDetail.Product[0].Outline }}</p>
            </div>
            <div class="product-operation flex align-center">
              <p :class="{ 'product-operation-check': operationIndex == 0 }" @click="operationIndex = 0">
                {{ $t('product.ProductDetails') }}</p>
              <p :class="{ 'product-operation-check': operationIndex == 1 }" @click="operationIndex = 1">
                {{ $t('product.ServiceSupport') }}</p>
            </div>
          </div>
        </div>
        <p class="content-line"></p>
        <div v-if="operationIndex == 0" v-html="productDetail.Product[0].Contents"></div>
        <div v-if="operationIndex == 1">
          <div class="support_title">
            <div>{{ $t('support.Category') }}</div>
            <div>{{ $t('support.Title') }}</div>
            <div>{{ $t('support.FileType') }}</div>
            <div>{{ $t('support.FileSize') }}</div>
            <div>{{ $t('support.ReleaseDate') }}</div>
            <div>{{ $t('support.Controls') }}</div>
          </div>
          <div class="support_title" v-for="item in productDetail.Support">
            <div>{{ item.SuptCate }}</div>
            <div>
              <span>{{ item.Title }}</span>
            </div>
            <div>{{ item.FileExt }}</div>
            <div>{{ item.FileSize }}</div>
            <div>{{ item.PostDate }}</div>
            <p class="suptcate-down" @click="downFile(item.SupportID, item.Title)">{{ $t('support.Downloads') }}</p>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import leftMenu from "@/components/leftMenu.vue";
import { productLine, productList, productInfo, supportDownload } from "@/api";

let res = [];
let detailData = [];
let _type = 0;
let _type1 = 0;

export default {
  name: "produts",
  components: {
    leftMenu,
  },
  data() {
    return {
      data: '',
      product: [],
      longMenuArr: [],
      flag: true, //true显示列表不显示详情
      menuType: "long",
      qq: 0,
      type: 0,
      elementOffsetTop: 0,
      pic1: "",
      pic2: "",
      pic3: "",
      clickIndex: 0,
      productDetail: null,
      operationIndex: 0,
      filterProduct: [],
    };
  },

  computed: {
    ...mapState({
      ProductsLeftClick: (state) => state.productsLeftClick,
      productsBottom: (state) => state.productsBottom,
      locale: (state) => state.locale,
    }),
  },
  watch: {
    '$route.path': {
      handler(newValue, oldValue) {
        if (newValue.query.showDetails == 'false') {
          this.flag = false;
          this.getProductList(oldValue.query._type)
          // this.entryDetail(oldValue.query.id, oldValue.query._type)
        } else {
          this.getProductList(newValue.query._type)
          this.flag = true;
        }
      }
    },
    productsBottom(val) {
      this.elementOffsetTop = this.$refs.box.offsetTop;
      this.$("html,body").animate({ scrollTop: this.elementOffsetTop }, 500);
      this.clickIndex = this.botClickIndex;

      // _type = this.botClickIndex;

      // _type1 = this.botClickIndex;
      // this.setDetailData();
      // this.changeShow();

      // this.setDetailData1();
      // this.changeShow1();
    },
    // 监听点击底部后显示相应内容
    ProductsLeftClick: {
      deep: true,
      handler(newValue) {
        this.clickIndex = newValue
        let num = this.ProductsLeftClick
        this.getProductList(this.longMenuArr[num].Line);
      }
    },
    locale: {
      async handler() {
        // this.getProductList(this.longMenuArr[this.clickIndex].Line)
        let index = this.$route.query._type
        this.getProductLine()
      }
    }
  },
  created() {
    console.log(' $route', this.$route)
    let index = this.$route.query._type
    let ProductID = this.$route.query.id
    //获取产品线数据
    this.getProductLine(index)
    if (ProductID) {
      this.getProductInfo(ProductID)
      this.entryDetail(ProductID, index)
    }
  },
  mounted() {
    this.$store.commit("setChangeLiBackGround", 3)
    window.scrollTo(0, 0);
  },
  methods: {
    //获取产品线数据
    getProductLine(_type) {
      let params = {};
      productLine(params).then((res) => {
        this.longMenuArr = res.Line;
        if (_type) { this.clickIndex = res.Line.map((item) => item.Line).indexOf(_type); } else {

          this.$router.replace({
            query: { _type: res.Line[this.clickIndex].Line }
          })

        }
        this.getProductList(_type || res.Line[this.clickIndex].Line);
      });
    },
    //获取产品列表
    getProductList(Line) {
      let params = { Line };
      productList(params).then((res) => {
        // console.log(res);
        this.product = res.Product;
        this.filterProduct = res.Product;
      });
    },
    // getNewsDetail(data) {
    //   this.flag = false;
    //   this.setDetailData();
    //   console.log('跳转数据', data)
    //   // 获取产品列表
    //   this.getProductInfo(data);
    // },
    // //获取产品详情
    getProductInfo(ID) {
      let params = { ID };
      productInfo(params).then((res) => {
        // console.log(res);
        var div = document.createElement("div");
        div.innerHTML = res.Product[0].Contents;
        res.Product[0].Contents = div.textContent || div.innerText;
        this.productDetail = res;
      });
    },
    // 查看详情
    entryDetail(ProductID, index) {
      // console.log(ProductID);
      // this.setDetailData();
      this.flag = false;
      this.elementOffsetTop = this.$refs.box.offsetTop;
      window.scrollTo(0, this.elementOffsetTop);

      this.$router.push({
        query: {
          _type: this.filterProduct[index].Line,
          id: ProductID,
          showDetails: false
        }
      })
      // 获取产品列表
      this.getProductInfo(ProductID);
    },
    // 左侧栏
    changetype(data) {
      if (this.flag == true) {
        if (data == this.clickIndex) {
          return
        }
      }

      this.getProductList(this.longMenuArr[data].Line);
      this.$router.push({
        query: {
          _type: this.longMenuArr[data].Line,
          showDetails: true
        }
      })
      this.clickIndex = data;
      this.flag = true;
      this.operationIndex = 0;
    },
    // changeShow() {
    //   this.flag = true;
    //   if (_type == 0) {
    //     this.product = res;
    //   } else {
    //     this.product = [];
    //     for (let i = 0; i < res.length; i++) {
    //       if (res[i].category == _type) {
    //         this.product.push(res[i]);
    //       }
    //     }
    //   }
    // },
    // setDetailData() {
    //   for (let i = 0; i < detailData.length; i++) {
    //     if (detailData[i].type == _type) {
    //       this.pic1 = detailData[i].pic1;
    //       this.pic2 = detailData[i].pic2;
    //       this.pic3 = detailData[i].pic3;
    //       return;
    //     } else {
    //       // _type = 0;
    //       // console.log(this.pic3)
    //       // this.type = 0;
    //     }
    //   }
    // },
    // 点击下载
    downFile(ID, title) {
      let data = { ID }
      supportDownload(data).then((res) => {
        console.log(res);
        this.download(res.Urls, title)
      })
    },
    download(url, title) {
      const a = document.createElement("a");
      a.download = title;
      a.target = "_blank";
      a.style.display = "none";
      a.href = url;
      a.click();
      document.body.removeChild(a);
    },
  },

  // changeShow1() {
  //   this.flag = true;
  //   if (_type1 == 0) {
  //     this.product = res;
  //   } else {
  //     this.product = [];
  //     for (let i = 0; i < res.length; i++) {
  //       if (res[i].category == _type1) {
  //         this.product.push(res[i]);
  //       }
  //     }
  //   }
  // },
  // setDetailData1() {
  //   for (let i = 0; i < detailData.length; i++) {
  //     if (detailData[i].type == _type1) {
  //       this.pic1 = detailData[i].pic1;
  //       this.pic2 = detailData[i].pic2;
  //       this.pic3 = detailData[i].pic3;
  //       console.log("成功");
  //       return;
  //     } else {
  //       console.log("失败");
  //     }
  //   }
  // },

  showTypeData() {
    // _type = this.$route.params._type;
    // this.setDetailData();
    // this.elementOffsetTop = this.$refs.box.offsetTop;
    // window.scrollTo(0, this.elementOffsetTop);
  },
  // filters: {
  //   dataFormat(msg) {
  //     switch (msg) {
  //       case 1:
  //         return "> 洗衣机";
  //         break;
  //       case 2:
  //         return "> 烘干机";
  //         break;
  //       case 3:
  //         return "> 熨平机";
  //         break;
  //       case 4:
  //         return "> 折叠机";
  //         break;
  //       case 5:
  //         return "> 干洗机";
  //         break;
  //       case 6:
  //         return "> 其他";
  //         break;
  //     }
  //   },
  // },
};
</script>

<style scoped>
.pageCore {
  width: 100%;
  min-width: 1200px;
}

.subnav {
  width: 100%;
  height: 60px;
  background-color: #f7f9fc;
}

.subnavDiv {
  width: 1200px;
  height: 60px;
  margin: auto;
  /* background-color: pink; */
}

.main {
  width: 1200px;
  margin: auto;
  padding: 50px 0;
  display: flex;
  /* background-color: #f7f9fc; */

  /* background-color: pink; */
}

.leftNav {
  width: 285px;
  /* background-color: pink; */
  float: left;
}

.content {
  width: 915px;
  /* background-color: yellow; */
  /* height: 1500px; */
}

.productDiv {
  width: 280px;
  margin-left: 25px;
  background-color: white;
  position: relative;
  float: left;
  margin-bottom: 40px;
}

.productDivImg {
  width: 280px;
  height: 280px;
  margin-bottom: 24px;
  background-color: #f7f9fc;
  vertical-align: middle;
  position: relative;
}

.productDivPic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  /* background-color: greenyellow; */
}

.productType {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #333;
  /* background-color: yellow; */
}

.productLine {
  width: 36px;
  height: 2px;
  margin-bottom: 18px;
  background-color: #0097d8;
}

.productText {
  font-size: 14px;
  color: #999;
}

.productDetail {
  width: 120px;
  height: 36px;
  background-color: #0097d8;
  color: #fff;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.detail {
  width: 895px;
  padding-bottom: 120px;
  margin-left: 20px;
  /*
   width: 894px;
   background-color: green; */
}


.publicName {
  font-size: 30px;
  line-height: 60px;
  margin-top: 60px;
}

.publicLine {
  width: 77px;
  height: 4px;
  background-color: #0097d8;
  margin-bottom: 75px;
}

.picGap {
  margin-top: 60px;
}

.pubilcImg {
  width: 894px;
}

.ability {
  margin-top: 20px;
}

.abilitytext {
  font-size: 18px;
  line-height: 36px;
  color: #333;
}

.clearTop {
  margin-top: -15px;
}

.menuCont {
  line-height: 60px;
}

.subnavDivText {
  float: left;
  line-height: 60px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  /* cursor: hand; */
  /* cursor: pointer; */
}

/* .subnavDivText:hover {
  color: red;
} */


.product-img {
  position: relative;
  width: 480px;
  height: 360px;
  background-color: #c3c6f3;
}

.product-img>img,
.product-img>video {
  width: 100%;
  height: 100%;
}

.product-title {
  font-size: 20px;
  font-weight: bold;
}

.product-gap {
  margin: 10px 0;
  width: 40px;
  height: 3px;
  background-color: #63bee6;
}

.product-model {
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
}

.product-outline {
  color: #b4c2cb;
  font-size: 16px;
}

.product-operation {
  width: 240px;
  height: 36px;
  border: 2px solid #0097d8;
}

.product-operation>p {
  width: 50%;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: #0097d8;
  cursor: pointer;
}

.product-operation-check {
  color: white !important;
  background-color: #0097d8;
}


.suptcate-down {
  width: 80px;
  height: 30px;
  border: 2px solid #73c6e9;
  text-align: center;
  line-height: 30px !important;
  color: #0097d8;
  cursor: pointer;
}

/**/
.content-line {
  margin: 10px 0 20px;
  width: 100%;
  height: 3px;
  background-color: #63bee6;
}

.support_title {
  text-align: left;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0097d8;
  font-size: 14px;
}

.support_title>div:nth-child(1) {
  width: 80px;
  text-align: center;
  padding-left: 20px;
}

.support_title>div:nth-child(2) {
  width: 200px;
  text-align: center;
}

.support_title>div:nth-child(3) {
  width: 100px;
  text-align: center;
}

.support_title>div:nth-child(4) {
  width: 100px;
  text-align: center;
}

.support_title>div:nth-child(5) {
  width: 100px;
  text-align: center;
}

.support_title>div:nth-child(6) {
  width: 100px;
  text-align: center;
}

.content-gaugeOutfit {
  margin: 0 auto;
  width: calc(100% - 20px);
  border-bottom: 2px solid #73c6e9;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.content-gaugeOutfit>span:nth-child(1) {
  padding-left: 2%;
}

.content-gaugeOutfit>span:nth-child(2) {
  padding-left: 25%;
}

.content-gaugeOutfit>span:nth-child(3) {
  padding-left: 25%;
}






.suptcate-box {
  width: calc(100% - 20px);
  margin: 0 auto;
  border-top: 2px solid #73c6e9;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suptcate-box>p {
  font-size: 14px;
  line-height: 14px;
}

/*
.cap {
  width: 100%;
  overflow: hidden;
  /* margin-bottom: 60px; */
/* background-color: pink; 
}

.capPic {
  width: 457px;
  height: 457px;
  background-color: #f7f9fc;
}

.capContent {
  width: 437px;
  height: 457px;
  padding-left: 55px;
  padding-top: 47px;
  box-sizing: border-box;
  background-color: #fff;
}

.capContentTitle {
  font-size: 30px;
  color: #0097d8;
  font-weight: 500;
  margin-bottom: 20px;
}

.capContentLine {
  width: 100%;
  height: 1px;
  margin-bottom: 25px;
  background-color: #0097d8;
}

.capContentType {
  font-size: 20px;
  line-height: 50px;
  color: #333;
}*/
</style>

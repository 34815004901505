<template>
    <div class="produts">
        <div style="min-width: 1400px">
            <img class="pageCore" src="../assets/supportTopIMG.jpg" alt />
        </div>
        <div class="subnav">
            <div class="subnavDiv">
                <p class="subnavDivText"><a href="" style="text-decoration: none; color: inherit;">{{ $t('support.Home')
                }}</a></p>
                <p class="subnavDivText"> {{ $t('support.Support') }}</p>
                <p class="subnavDivText" style="margin-left: 10px">{{ clickIndex == null ? '' : ' > ' +
                    supportMenuArr[clickIndex]?.Name }}</p>
            </div>
        </div>
        <div class="main" ref="box">
            <div class="leftNav">
                <leftMenu :menuType="menuType" :clickIndex="clickIndex" :supportMenuArr="supportMenuArr"
                    @activeType="changetype">
                </leftMenu>
            </div>
            <div class="content">
                <div class="support_inputBox">
                    <input v-model.trim="filterContent" type="text" class="support_input"
                        :placeholder="$t('support.QueryName')">
                    <div @click="searchFilterData" class="support_inputFor">{{ $t('support.Search') }}</div>
                    <img v-if="filterContent" class="support_close" src="../assets/close.png" alt=""
                        @click="searchResetting">
                </div>
                <div class="support_title">
                    <div>{{ $t('support.Category') }}</div>
                    <div>{{ $t('support.Title') }}</div>
                    <div>{{ $t('support.FileType') }}</div>
                    <div>{{ $t('support.FileSize') }}</div>
                    <div>{{ $t('support.ReleaseDate') }}</div>
                    <div>{{ $t('support.Controls') }}</div>
                </div>
                <div class="support_title" v-for="(item, index) in filterSupport" :key="index">
                    <div>{{ item.SuptCate }}</div>
                    <div style="text-align: left;">
                        <span>{{ item.Title }}</span>
                        <!-- <p>{{ item.Outline }}</p> -->
                    </div>
                    <div>{{ item.FileExt }}</div>
                    <div>{{ item.FileSize }}</div>
                    <div>{{ item.PostDate }}</div>
                    <div class="support_download" @click="getsupportDownload(item.SupportID, item.Title)">
                        {{ $t('support.Downloads') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import leftMenu from "@/components/leftMenu.vue";
import { AttributeContent, supportList, supportDownload } from "@/api";
export default {
    name: "support",
    components: {
        leftMenu,
    },
    data() {
        return {
            support: [],
            filterSupport: [],
            filterContent: "",
            supportMenuArr: [],
            menuType: "support",
            clickIndex: 0,
            supportDownload: '',
            elementOffsetTop: 0,
        };
    },
    computed: {
        ...mapState({
            supportClick: (state) => state.supportClick,
            supportBottom: (state) => state.supportBottom,
            locale: (state) => state.locale,
        }),
    },
    watch: {
        supportClick(val) {
            this.elementOffsetTop = this.$refs.box.offsetTop;
            this.$("html,body").animate({ scrollTop: this.elementOffsetTop }, 500);
            this.clickIndex = this.botClickIndex;
        },
        // 监听点击底部后显示相应内容
        supportClick: {
            deep: true,
            handler(newValue) {
                this.clickIndex = newValue
                let num = this.supportClick
                this.getsupportList(this.supportMenuArr[num].Name);
            }
        },
        locale: {
            handler() {
                // 支持类别
                this.getAttributeContent();
            }
        }
    },
    created() {

    },
    mounted() {
        this.$store.commit("setChangeLiBackGround", 6)
        window.scrollTo(0, 0);
        let _type = this.$route.query._type;
        // 支持类别
        this.getAttributeContent(_type);
    },
    methods: {
        getAttributeContent(_type) {
            let data = {};
            AttributeContent(data).then((res) => {
                // console.log(res);
                this.supportMenuArr = res.SuptCate;
                res.SuptCate.unshift({ Name: this.$t('support.AllVideos') })
                if (_type) {
                    this.clickIndex = res.SuptCate.map((item) => item.Name).indexOf(_type);
                } else {
                    this.$router.replace({
                        query: {_type:res.SuptCate[this.clickIndex].Name}
                    })
                }
                this.getsupportList(_type || res.SuptCate[0].Name);
            });
        },
        getsupportList(SuptCate) {
            if (SuptCate == this.$t('support.AllVideos')) SuptCate = ""
            let data = { SuptCate };
            supportList(data).then((res) => {
                // console.log(res);
                this.support = res.Support;
                this.filterSupport = res.Support;
            });
        },
        // 左侧
        changetype(data) {
            if (data == this.clickIndex) return
            this.getsupportList(this.supportMenuArr[data].Name);
            this.$router.replace({ query: { _type: this.supportMenuArr[data].Name } })
            this.clickIndex = data;
            this.flag = true;
            this.operationIndex = 0;
        },

        // 点击下载
        getsupportDownload(ID, title) {
            let data = { ID }
            supportDownload(data).then((res) => {
                // console.log(res);
                this.download(res.Urls, title)
            })
        },
        download(url, title) {
            const a = document.createElement("a");
            a.download = title;
            a.target = "_blank";
            a.style.display = "none";
            a.href = url;
            a.click();
            document.body.removeChild(a);
        },
        // 搜索
        searchFilterData() {
            let listName = this.support.map(item => item.Title)
            let filterList = []
            for (let i = 0; i < listName.length; i++) {
                if (listName[i].indexOf(this.filterContent) != -1) {
                    filterList.push(this.support[i])
                }
            }
            this.filterSupport = filterList
        },
        // 重置
        searchResetting() {
            this.filterContent = "";
            this.searchFilterData();
        }
    },
};
</script>
<style></style>
<style scoped>
.pageCore {
    width: 100%;
    min-width: 1200px;
}

.subnav {
    width: 100%;
    height: 60px;
    background-color: #f7f9fc;
    margin-bottom: 50px;
}

.subnavDiv {
    width: 1200px;
    height: 60px;
    margin: auto;
    /* background-color: pink; */
}

.subnavDivText {
    float: left;
    line-height: 60px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    /* cursor: hand; */
    /* cursor: pointer; */
}

.main {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 50px;
    /* background-color: #f7f9fc; */
    /* background-color: pink; */
}

.leftNav {
    width: 285px;
    /* background-color: pink; */
}

.content {
    width: 895px;
    margin-left: 20px;
    /* background-color: yellow; */
    /* height: 1500px; */
}

.support_inputBox {
    height: 36px;
    /* display: grid;
  grid-template-columns: repeat(1, 610px);
  grid-gap: 20px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.support_input {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
}

input::-webkit-input-placeholder {
    /* WebKit browsers */
    padding-left: 5px;
}

.support_input:focus {
    outline: none;
}

.support_inputFor {
    width: 120px;
    height: 100%;
    background-color: #0097d8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.support_close {
    position: absolute;
    right: 120px;
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.support_title {
    text-align: left;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0097d8;
    color: #7d7f81;
    font-size: 14px;
}

.support_title>div:nth-child(1) {
    width: 80px;
    text-align: center;
    padding-left: 20px;
}

.support_title>div:nth-child(2) {
    width: 200px;
    text-align: center;
}

.support_title>div:nth-child(3) {
    width: 100px;
    text-align: center;
}

.support_title>div:nth-child(4) {
    width: 100px;
    text-align: center;
}

.support_title>div:nth-child(5) {
    width: 100px;
    text-align: center;
}

.support_title>div:nth-child(6) {
    width: 100px;
    text-align: center;
}

.support_center {
    height: 80px;
    border-top: 1px solid #0097d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7d7f81;
}

.support_center>div:nth-child(1) {
    width: 100px;
    text-align: center;
}

.support_center>div:nth-child(2) {
    width: 150px;
    text-align: center;
}

.support_center>div:nth-child(3) {
    width: 100px;
    text-align: center;
}

.support_center>div:nth-child(4) {
    width: 100px;
    text-align: center;
}

.support_center>div:nth-child(5) {
    width: 100px;
    text-align: center;
}

.support_download {
    width: 80px;
    height: 36px;
    border: 1px solid #0097d8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0097d8;
    cursor: pointer;
}
</style>
  
<template>
  <div class="pageAll">
    <div class="contAll">
      <div class="bannerDiv">
        <img class="banner" src="../assets/gsjsTopimg.jpg" alt="">
      </div>
      <div class="menu">
        <div class="menuCont"><a href="" style="text-decoration: none; color: inherit;">{{$t('support.Home')}}</a> > {{$t('introduce.AboutGamesail')}}</div>
      </div>
      <div class="detailsCont" ref="box">
        <div class="companyIntroduce" ref="box1" v-for="(item, index) in showData" :key="index">
          <div class="title">{{ item.Title }}</div>
          <div class="yellow"></div>
          <div class="english">{{ item.SubTitle }}</div>
          <div class="contWord" v-html="item.Contents"></div>
          <!-- <div class="imgDIV">
                    <img class="introduceImg" :src="showData.companyIntroduce.imgUrl" alt="">
                </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { companyContent } from "@/api";
export default {
  name: "introduce",
  data() {
    return {
      elementOffsetTop: 0,//元素到顶部的距离
      showData: [],//公司介绍
      // showData:{
      //     companyIntroduce:{
      //       title:"企业介绍",
      //       english:"ASSOCIATION NEWS",
      //       desc:[
      //         "浙江赛航机械有限公司地处浙江省嘉兴市海盐县经济技术开发区杭州湾跨海大桥北岸，厂区建筑面积20000多平方米。离上海、苏州、无锡、杭州、宁波、绍兴等地距离均在百公里左右，地理位置优越。是一家经营工业洗涤设备的专业公司，专业生产水洗设备、熨烫设备、干衣设备、干洗设备及相关辅助设备等70多个品种，可满足各界洗涤设备用户的需求。",
      //         "“更专业的服务，更好的性价比”是公司得以持续发展的宗旨。赛航公司拥有一批专业从事洗涤设备开发、生产、销售、服务近30年的专业人才，其中高、中级的职称的工程技术人员占全体员工数量30％以上，技术力量雄厚，每年不断研发新产品，具有国内领先，国际领先的新产品，不断获得国家发明专利。各种专业配套设备齐全，生产工艺先进，具有完善的质量保障体系，产品质量严格把关，精益求精，合格率高。多年来，在科技人员的不断努力下，集众家之长，精心研制机械产品，各项技术指标优先于国家标准，在同行业中名列前茅。公司不仅可以为用户提供一流的设备、优质及时的售后服务，更可以为用户提供具有附加值的一体化解决方案，百分百满足客户的真实需求。",
      //         "在新的起点，新的机遇面前，浙江赛航将始终以高水平、上档次、上规模、高质量、高科技、高品质的开拓精神，以更多、更好、更新的产品满足客户不断更新的需求。"
      //       ],
      //       imgUrl:require("../assets/company-introduce/introduceImg.jpg"),
      //     },
      //     cooperation:{
      //       title:"商务合作",
      //       english:"BUSINESS COOPERATION",
      //       desc:[
      //         {imgUrl:require("../assets/company-introduce/cooperation1.jpg"),title:"节能理念",desCont:"赛航拥有一支具有丰富专业知识的研发团队，其中既有二十多年专业设计经验的国内技术人员，也有国际著名的洗涤设备设计专家。通过对国内国际设备先进技术的了解学习，我们不断创新，设计开发出赛航特有的G系列产品。其设计先进独到，产品 能耗更低，洗涤周期更短，引领行业设计潮流。"},
      //         {imgUrl:require("../assets/company-introduce/cooperation2.jpg"),title:"卓越品质",desCont:"领先的技术实力和完善的品质体系是赛航运营的两大基石。赛航高度重视产品品质，以建立行业一流品质为目标，树立科学的质量理念，制定了严格的质量管理规范。拥有ISO9001国际质量管理体系，欧盟CE,ISO14001环境管理体系等认证证书。赛航产品故障率低，寿命远超行业平均水准，品质卓越。"},
      //         {imgUrl:require("../assets/company-introduce/cooperation5.jpg"),title:"专业服务",desCont:"赛航拥有一批专业从事多年洗涤设备开发、生产、销售、服务的专业人才，为客户提供优质及时的售后服务。针对客户不同需求，提供专业的一体化解决方案，让客户花最少的成本，收获最大的利益，与客户共同发展。"},
      //         {imgUrl:require("../assets/company-introduce/cooperation4.jpg"),title:"合理的价位",desCont:"赛航秉承可持续发展，与合作伙伴长期共赢的理念，追求的目标是“工业洗涤设备性价比永远第一”。保证卓越品质的同时，拥有极具竞争性的价位。超高的性价比得到合作伙伴的认可。"},
      //       ],
      //       imgUrl:[require("../assets/company-introduce/cooperation1.jpg"),require("../assets/company-introduce/cooperation2.jpg"),require("../assets/company-introduce/cooperation3.jpg"),require("../assets/company-introduce/cooperation4.jpg")]
      //     },
      //     network:{
      //       title:"售后网络",
      //       english:"DISTRIBUTION NETWORK",
      //       desc:[" 浙江赛航机械有限公司"," 售后维修网点遍布全国各省，直辖市，自治区。"],
      //       imgUrl:require("../assets/company-introduce/network.png")
      //     },
      //     aptitude:{
      //       title:"公司资质",
      //       english:"COMPANY QUALIFICATION",
      //       imgUrl:require("../assets/company-introduce/aptitude.png")
      //     },
      //     device:{
      //       title:"生产设备",
      //       english:"PRODUCTION EQUIPMENT",
      //       imgUrl:require("../assets/company-introduce/device.jpg")
      //     },
      //     statement:{
      //       title:"版权声明",
      //       english:"COPYRIGHT NOTICE",
      //       desc:[
      //         "浙江赛航机械有限公司官网上刊载的所有内容，包括但不限于文字报道、图片、声音、录像、图表、标志、标识、广告、商标、商号、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及其他任何产品信息，均受《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》及适用之国际公约中有关著作权、商标权、专利权及／或其它财产所有权法律的保护，为浙江赛航机械有限公司，和／或相关权利人专属所有或持有。",
      //         "“浙江赛航机械有限公司”、“supersail”等名称、商标或标识的商标权、著作权及相关权利均属浙江赛航机械有限公司所有，任何人不得擅自使用，亦不得以类似或近似域名、商标、标识、商号、名称等任何形式做混淆使用。",
      //         "未经浙江赛航机械有限公司的书面许可，任何人（包括但不限于媒体、网站、公司、其他组织、个人）不得以任何形式在国际互联网、局域网、手机网络上变更、发行、播送、转载、复制、重制、改动、散布、表演、展示浙江赛航机械有限公司所有的音视频资源和捕捉音视频资源而形成的图像；也不得以任何方式，将网站内的任何内容（包括但不限于流媒体、图片和文字）和服务作全部或局部变更、发行、播送、复制、下载、转载、传播、引用、重制、改动、散布、表演、展示，否则将以侵权论，依法追究法律责任。",
      //         "本网站所登载内容均为通过合法渠道自行取得或由具备独立承担法律责任能力的第三方提供，若您发现本网站所登载内容侵犯到您的著作权，在向浙江赛航机械有限公司提供相关证据并得到确认的情况下，我们将在24小时内移除相关争议内容。"
      //       ],
      //     },
      // },
    }
  },
  computed: {
    ...mapState({
      introduceClick: state => state.introduceClick,
      introduceBottom: state => state.introduceBottom,
      locale: (state) => state.locale,
    })
  },
  watch: {
    introduceBottom(val) {
      this.elementOffsetTop = this.$refs.box.offsetTop;
      this.$("html,body").animate({ scrollTop: this.elementOffsetTop }, 500);
      this.clickIndex = this.botClickIndex;
      // var boxNum = "box"+(val+1);
      this.getElementTop(this.botClickIndex);
      console.log(this.$refs)
      this.$('html,body').animate({ scrollTop: this.elementOffsetTop }, 500);
      if (this.$refs.box) {
        this.elementOffsetTop = this.$refs.box.offsetTop;
        this.$('html,body').animate({ scrollTop: this.elementOffsetTop }, 500);
        this.clickIndex = this.botClickIndex;
      }
    },
    introduceClick: {
      deep: true,
      handler(newValue) {
        this.clickIndex = newValue
        this.getElementTop(this.$route.params._type);
      }
    },
    locale: {
      handler() {
        this.getCompanyContent()
      }
    }
  },
  created() {
    //获取公司介绍
    this.getCompanyContent()
  },
  mounted() {
    this.$store.commit("setChangeLiBackGround", 2)
    if (this.$route.params._type === 0 || this.$route.params._type) {
      this.getElementTop(this.$route.params._type);
      window.scrollTo(0, this.elementOffsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    //获取公司介绍
    getCompanyContent() {
      let params = {}
      companyContent(params).then(res => {
        res.Content.forEach(item => {
          var div = document.createElement('div');
          div.innerHTML = item.Contents
          item.Contents = div.textContent || div.innerText
        })
        // console.log(res.Content)
        this.showData = res.Content
      })
    },
    getElementTop(val) {//获取元素到顶部距离
      // console.log(val);
      if (val === 0) {
        this.elementOffsetTop = this.$refs.box1.offsetTop;
      } else if (val === 1) {
        this.elementOffsetTop = this.$refs.box2.offsetTop;
      } else if (val === 2) {
        this.elementOffsetTop = this.$refs.box3.offsetTop;
      } else if (val === 3) {
        this.elementOffsetTop = this.$refs.box4.offsetTop;
      }
      //  else if (val === 4) {
      //   this.elementOffsetTop = this.$refs.box5.offsetTop;
      // } else if (val === 5) {
      //   this.elementOffsetTop = this.$refs.box6.offsetTop;
      // }
    }
  }
}
</script>
<style scoped="scoped">
.bannerDiv {
  min-width: 1400px;
}

.banner {
  width: 100%;
  vertical-align: bottom;
}

.detailsCont {
  width: 1200px;
  padding: 0 100px;
  margin: 0 auto;
}

.menu {
  width: 100%;
  height: 60px;
  background-color: #F7F9FC;
}

.menuCont {
  width: 1200px;
  padding: 0 100px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  margin: 0 auto;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.title {
  width: 1200px;
  margin: 0 auto;
  margin-top: 120px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(0, 151, 216, 1);
  text-align: center;
}

.yellow {
  width: 77px;
  height: 4px;
  margin: 22px auto 30px auto;
  background: rgba(255, 208, 44, 1);
  border-radius: 2px;
}

.english {
  width: 1200px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 98px;
  text-align: center;
}
.contWord{
  padding: 10px 0;
  line-height: 30px;
}
.contWord div {
  width: 1200px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  margin-bottom: 55px;
}
.contWord p{
  /* line-height: 50px; */
}
.introduceImg {
  width: 1200px;
  height: 723px;
  vertical-align: bottom;
}

.imgWord {
  overflow: hidden;
  margin-bottom: 80px;
}

/*.showImg,.showWord{*/
/*    float: left;*/
/*}*/
.cooperationImg {
  width: 590px;
  height: 394px;
}

.showWord-title {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 48px;
  text-align: left;
}

.showWord-cont {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 38px;
  text-align: justify;
}

.showWord {
  width: 589px;
  margin-top: 50px;
}

.network-Word div {
  width: 100%;
  margin: 0 auto;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 48px;
  text-align: center;
}

.imgDIV {
  margin-top: 99px;
  text-align: center;
}

.networkImg {
  width: 987px;
  height: 485px;
  margin: 0 auto;
}

.statementAll {
  width: 100%;
  background-color: #F7F9FC;
  padding-top: 119px;
  padding-bottom: 260px;
  margin-top: 120px;
}

.statement {
  width: 1200px;
  margin: 0 auto;
}

.statement-Word div {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 36px;
  margin-top: 55px;
  text-align: justify;
}

.aptitudeImg {
  width: 1200px;
  height: 898px;
}

deviceImg {
  width: 1186px;
  height: 887px;
}
</style>

export default {
  "lang": "English",
  "introduce": {
    "Company": "Introduction",
    "Products": "Products",
    "solution": "Solution",
    "Support": "Support",
    'AboutGamesail':'About gamesail'
  },
  "support": {
    'Home': 'Home',
    'Support': 'Support & Downloads',
    'Search': 'Search',
    'Category': 'Category',
    'Title': 'Title',
    'FileType': 'File type',
    'FileSize': 'File size',
    'ReleaseDate': 'Release date',
    'Controls': 'Controls',
    'Downloads': 'Downloads',
    'QueryName': 'Query name',
    'AllVideos':'All videos'
  },
  "Customer": {
    'CustomerCenter': 'Customer service center',
    'Telephone': 'Telephone',
    'OnlineService': 'Online',
    'WelcomeConsultation': 'Welcome your consultation',
    'liu':'Liu',
  },
  "solve": {
    'solve': 'Solve',
    'ProjectDescription': 'Project description'
  },
  "product":{
    'ProductCenter':'Products',
    'ViewDetails':'Details',
    'ProductDetails':'Product details',
    'ServiceSupport':'support',
    
  },
  "index":{
    'IndustrySolutions':'Industry solutions',
    'MoreProducts':'More',
    'NewsCenter':'News center',
    'LearnMore':'Learn more'
  },
  'contact':{
    'NewsInformation':'News',
    'Detail':'Details',
    'Date':'Date'
  },
  'head':{
    'Wechat':'Wechat public account',
    'ProductName':'Product name',
    'EnterProductName':'Please enter the product name',
    'AllNews':'All news',
    'companyName':'Shanghai Gamesail Washing Machine CO.LTD',
    
    'timeOut':'The network request timed out'
  }
}

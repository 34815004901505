<template>
  <div class="produts">
    <div style="min-width: 1400px">
      <img class="pageCore" src="../assets/newsTopIMG.jpg" alt />
    </div>
    <div class="subnav">
      <div class="subnavDiv">
        <p class="subnavDivText"><a href="" style="text-decoration: none; color: inherit;">{{ $t('support.Home') }}</a></p>
        <p class="subnavDivText">> {{ $t('contact.NewsInformation') }}</p>
        <p class="subnavDivText" style="margin-left: 10px">{{ clickIndex == null ? '' : ' > ' +
          contactMenuArr[clickIndex]?.Name }}</p>
      </div>
    </div>
    <div class="main" ref="box">
      <div class="leftNav">
        <leftMenu :menuType="menuType" :clickIndex="clickIndex" :contactMenuArr="contactMenuArr" @activeType="changetype">
        </leftMenu>
      </div>
      <div class="content" v-if="flag">
        <div class="news-topic">
          <div class="news-box" v-for="(item, index) in news" :key="index">
            <p class="newsTextTop">{{ item.Title }}</p>
            <p class="newsTextCenter">{{ item.Outline }}</p>
            <div class="newsTextBottom">
              <div class="newsTextBtmLeft">{{ item.PostDate }}</div>
              <div class="newsTextBtmRight" @click="newsBut(item.NewsID, index)">{{ $t('contact.Detail') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail" v-if="!flag && information">
        <div class="detail-top">
          <span>{{ information.News[0].Title }}</span>
          <span>{{ $t('contact.Date') }}：{{ information.News[0].PostDate }}</span>
        </div>
        <div class="html-content" v-html="information.News[0].Contents"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import leftMenu from "@/components/leftMenu.vue";
import { AttributeContent, NewsList, NewsDetail } from "@/api";

export default {
  name: "contact",
  components: {
    leftMenu,
  },
  data() {
    return {
      news: [],
      contactMenuArr: [],
      menuType: "contact",
      elementOffsetTop: 0,
      clickIndex: 0,
      flag: true,
      information: null,
    };
  },
  created() {
    window.scrollTo(0, 0);

    let data = this.$route.query.id;
    // console.log(data);
    let boolean = this.$route.query.showDetails
    // console.log(boolean)
    let name = this.$route.query._type;
    // console.log(name);
    if (boolean == 'false') {
      this.getNewsDetail(data)
      // console.log('跳转数据', data)
      if (data) {
        this.flag = false
      }
      if (name) {
        this.contactMenuArr = name
      }
      // console.log(this.contactMenuArr);

      // this.clickIndex = name;
      // this.$store.commit("setProductsBottom", !this.productsBottom);
      // this.$store.commit("setProductsLeftClick", index);
    }
    // } else if (name) {

    //  }

    // 属性内容
    this.getAttributeContent(name);
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
    }),
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.query.showDetails === 'false') {
        this.flag = false;
        this.getNewsList(oldValue.query._type)
        // this.getNewsDetail(oldValue.query.id)
        // this.newsBut(oldValue.query.id, oldValue.query._type)
      } else {
        this.getNewsList(newValue.query._type)
        this.flag = true;
      }
    },
    locale: {
      handler() {
        this.getAttributeContent();
      }
    }
  },
  mounted() {
    this.$store.commit("setChangeLiBackGround", 5)

    // let index = this.$route.query._type;
    // let NewsID = this.$route.query.id;
    // console.log(NewsID, index);
    // if (NewsID) {
    //   this.getNewsList(index);
    //   this.getNewsDetail(NewsID)
    //   this.newsBut(NewsID, index)
    // }
  },
  methods: {
    // 属性内容
    getAttributeContent(_type) {
      let data = {};
      AttributeContent(data).then((res) => {
        // console.log(res);
        this.contactMenuArr = res.NewsCate;
        res.NewsCate.unshift({ Name: this.$t('head.AllNews') })
        if (_type) { this.clickIndex = res.NewsCate.map((item) => item.Name).indexOf(_type); } else {
          this.$router.replace({
            query: { _type: res.NewsCate[this.clickIndex].Name }
          })
        }
        this.getNewsList(res.NewsCate[0].Name);
      });
    },
    // 新闻列表
    getNewsList(NewsCate) {
      // console.log(NewsCate);
      if (NewsCate == this.$t('head.AllNews')) NewsCate = ""
      let data = { NewsCate };
      NewsList(data).then((res) => {
        // console.log(res);
        this.news = res.News;
      });
    },
    // 左侧
    changetype(data) {
      // console.log(data);
      if (this.flag == true) {
        if (data == this.clickIndex) return
      }
      this.getNewsList(this.contactMenuArr[data].Name);
      this.$router.replace({ query: { _type: this.contactMenuArr[data].Name, showDetails: true } })
      this.clickIndex = data;
      this.flag = true;
      this.operationIndex = 0;
    },
    // 详情
    newsBut(NewsID, index) {
      this.flag = false;
      this.elementOffsetTop = this.$refs.box.offsetTop;
      window.scrollTo(0, this.elementOffsetTop);
      // this.getNewsList(this.news[index].NewsCate);
      this.$router.push({
        query: {
          _type: this.news[index].NewsCate,
          id: NewsID,
          showDetails: false
        }
      })
      // 新闻详情
      this.getNewsDetail(NewsID)
      // console.log(NewsID);
    },
    getNewsDetail(ID) {
      let data = { ID }
      NewsDetail(data).then((res) => {
        // console.log(res);
        var div = document.createElement("div");
        div.innerHTML = res.News[0].Contents;
        res.News[0].Contents = div.textContent || div.innerText;
        this.information = res;
      })
    },
  },
};
</script>

<style>
.html-content {
  text-indent: 2em;
  text-align: justify;
}

.html-content p,
.html-content div {
  font-size: 14px;
  color: #828586;
  line-height: 30px;
}

.html-content img {
  width: 100%;
  height: auto;
  display: block;
}
</style>

<style scoped>
.pageCore {
  width: 100%;
  min-width: 1200px;
}

.subnav {
  width: 100%;
  height: 60px;
  background-color: #f7f9fc;
  margin-bottom: 50px;
}

.subnavDiv {
  width: 1200px;
  height: 60px;
  margin: auto;
  /* background-color: pink; */
}

.main {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 50px;
  /* background-color: #f7f9fc; */
  /* background-color: pink; */
  display: flex;
}

.leftNav {
  width: 285px;
  /* background-color: pink; */
  margin-right: 20px;
}

.content {
  width: 895px;
  /* background-color: yellow; */
  /* height: 1500px; */
}


.subnavDivText {
  float: left;
  line-height: 60px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  /* cursor: hand; */
  /* cursor: pointer; */
}


.news-topic {
  display: grid;
  grid-template-columns: repeat(2, 437.5px);
  grid-gap: 20px;
}

.news-box {
  height: 230px;
  padding: 15px 25px;
  box-sizing: border-box;
  background-color: #F9FBFC;
  position: relative;
}

.newsTextTop {
  font-size: 18px;
  color: #007AC8;
  margin-bottom: 10px;
  font-weight: 600;
}

.newsTextCenter {
  font-size: 14px;
  color: #605e5e;
  margin-bottom: 10px;
}

.newsTextBottom {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 180px;
  left: 0;
  padding: 0 25px;
  box-sizing: border-box;
}

.newsTextBtmLeft {
  font-size: 14px;
  color: #605e5e;
  margin-bottom: 5px;
  line-height: 40px;
}

.newsTextBtmRight {
  width: 80px;
  height: 30px;
  font-size: 12px;
  color: #0097d8;
  border: 1px solid #0097d8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.detail {
  width: 895px;
  background-color: rgb(255, 255, 255);
  /*box-shadow: 0px 0px 20px 0px rgba(182, 188, 204, 0.8);*/
  padding: 15px 25px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.detail-top {
  text-align: center;
  margin-bottom: 20px;
}

.detail-top>span:nth-child(1) {
  font-size: 26px;
  color: #6a6d6f;
}

.detail-top>span:nth-child(2) {
  font-size: 14px;
  color: #909294;
  display: block;
  padding-top: 20px;
  text-align: right;
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh'
import en from './en'
Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
  },
  zh: {
    ...zh,
  }
};
const Lang=sessionStorage.getItem('Lang')?sessionStorage.getItem('Lang'):navigator.language.startsWith('zh')?'zh':'en'
sessionStorage.setItem('Lang',Lang)
const i18n = new VueI18n({
  locale: Lang,
  messages,
});
export default i18n;

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    query: {},
    changeLiBackGround: 1, //改变头部菜单的背景色
    solveLeftClick: null, //选中解决方案页面左侧的菜单栏
    productsLeftClick: null, //选中产品中心页面左侧的菜单栏
    supportClick: null, //选中支持与下载页面左侧的菜单栏
    introduceClick: null, //底部导航进入公司介绍点击的模块

    showConsult: true, //显示咨询弹框
    solveBottom: false, //点击底部解决方案
    productsBottom: false, //点击底部产品中心
    supportBottom: false, //点击底部支持与下载
    introduceBottom: false, //点击底部公司介绍
    locale: sessionStorage.getItem('Lang')?sessionStorage.getItem('Lang'):navigator.language.startsWith('zh')?'zh':'en'
  },
  mutations: {
    SET_QUERY(state, query) {
      state.query = query;
    },
    setChangeLiBackGround(state, data) {
      state.changeLiBackGround = data;
    },
    // 选中解决方案页面左侧的菜单栏
    setSolveLeftClick(state, data) {
      state.solveLeftClick = data;
    },
    //选中产品中心页面左侧的菜单栏
    setProductsLeftClick(state, data) {
      state.productsLeftClick = data;
    },
    setIntroduceClick(state, data) {
      state.introduceClick = data;
    },
    setShowConsult(state, data) {
      state.showConsult = data;
    },
    // 解决方案
    setSolveBottom(state, data) {
      state.solveBottom = data;
    },
    // 产品中心
    setProductsBottom(state, data) {
      state.productsBottom = data;
    },
    // 公司介绍
    setIntroduceBottom(state, data) {
      state.introduceBottom = data;
    },
    //支持与下载
    setSupportBottom(state, data) {
      state.supportBottom = data;
    },

    setsupportClick(state, data) {
      state.supportClick = data;
    },
    setLocale(state, data) {
      state.locale = data
    }
  },
  actions: {},
  modules: {},
});

<template>
  <div class="menuAll">
    <div class="topMenu">
      <div v-if="menuType === 'long'">
        <p :class="{ whiteWord: clickIndex === index }" @click="clickMenuFn(index)" id="sidebar"
          :style="{ backgroundColor: clickIndex === index ? '#0097D8' : '#F7F9FC' }" class="menuCont"
          v-for="(item, index) in longMenuArr" :key="index">{{ item.Line }}</p>
      </div>
      <!-- 新闻资讯 -->
      <div v-if="menuType === 'contact'">
        <p :class="{ whiteWord: clickIndex === index }" @click="clickNews(index)"
          :style="{ backgroundColor: clickIndex === index ? '#0097D8' : '#F7F9FC' }" class="menuCont"
          v-for="(item, index) in contactMenuArr" :key="index">{{ item.Name }}</p>
      </div>
      <!-- 支持下载 -->
      <div v-if="menuType === 'support'">
        <p :class="{ whiteWord: clickIndex === index }" @click="clickSupport(index)"
          :style="{ backgroundColor: clickIndex === index ? '#0097D8' : '#F7F9FC' }" class="menuCont"
          v-for="(item, index) in supportMenuArr" :key="index">{{ item.Name }}</p>
      </div>
      <!-- 解决方案 -->
      <div v-if="menuType === 'short'">
        <p :class="{ whiteWord: clickIndex === index }" @click="skipDetails(index)"
          :style="{ backgroundColor: clickIndex === index ? '#0097D8' : '#F7F9FC' }" class="menuCont"
          v-for="(item, index) in shortMenuArr" :key="index">{{ item.Title }}</p>
      </div>
    </div>
    <div class="serviceCenter">
      <div class="serviceWord">{{$t('Customer.CustomerCenter')}}</div>
      <div class="contact">
        <div class="contact-box">
          <div class="contact-picture">
            <img src="../assets/phone.png" alt="">
            <span>{{$t('Customer.Telephone')}}</span>
          </div>
          <div class="contact-phone">
            {{$t('Customer.liu')}}：16628880837
          </div>
          <div class="contact-QRcode">
            <img src="../assets/BlackQRcode.png" alt="">
          </div>
          <p class="line"></p>
          <div class="contact-customer">
            <img src="../assets/customer.png" alt="">
            <span>{{$t('Customer.OnlineService')}}</span>
          </div>
          <div class="contact-qq">
            <img src="../assets/qq.png" alt="">
            <span>468100401</span>
          </div>
        </div>
        <!-- <img src="../assets/public/consultCont2.jpg" alt /> -->
      </div>
      <div class="consult">{{$t('Customer.WelcomeConsultation')}}!</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["menuType", "clickIndex", "shortMenuArr", "longMenuArr", 'contactMenuArr', 'supportMenuArr'],
  name: "leftMenu",
  data() {
    return {
      index: '',
      filterProducts: '',
      clickMenuShort: 0,
      clickMenuLong: 0,
      // 新闻资讯
      clickMenuContact: 0,
      clickMenuSupport: 0,
      // longMenuArr: ["全部","洗衣机","烘干机", "熨平机","折叠机","干洗机","其他"],
    };
  },
  mounted() {

   },
  // created(){
  //   this.index= this.$route.params.i;
  //   console.log(this.index);
  // },
  // watch: {
  // clickIndex(val) {
  // this.clickMenuShort = val;
  // this.clickMenuLong = val;
  // // 新闻资讯
  // this.clickMenuContact = val;
  // // 支持下载
  // this.clickMenuSupport = val;
  // },
  // },
  created() {
    // if (this.$route.query._type) {
    //   this.$emit("activeType", this.$route.query._type)
    //   console.log(this.$route.query._type);
    // }
  },
  methods: {
    clickMenuFn(index) {
      this.$emit("activeType", index);
    },
    // 解决方案
    skipDetails(index) {
      // this.clickIndex = index;
      // this.$store.commit("setSolveLeftClick", index);
      this.$emit("activeType", index);
    },
    // 新闻资讯
    clickNews(index) {
      // this.clickMenuContact = index;
      // this.$store.commit("setProductsLeftClick", index);
      this.$emit("activeType", index);
    },
    // 支持下载
    clickSupport(index) {
      // this.clickMenuSupport = index;
      // this.$store.commit("setProductsLeftClick", index);
      this.$emit("activeType", index);
    },
  },
};
</script>

<style scoped>
.menuCont {
  width: 285px;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  /* background-color: #f7f9fc; */
  background-color: red;
  cursor: pointer;
}

.menuCont-all {
  width: 285px;
  height: 80px;
  line-height: 80px;
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  /* background-color: #f7f9fc; */
  background-color: #0097D8;
  cursor: pointer;
}

.serviceCenter {
  margin-top: 20px;
}

.serviceWord {
  width: 285px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  background-color: #63bee6;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.consult {
  width: 285px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
  background-color: #63bee6;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.contact {
  width: 285px;
  height: 380px;
  background: #F7F9FC;
}

.contact-box {
  width: 230px;
  margin: 0 auto;
}

.contact-picture {
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.contact-picture>img {
  width: 29px;
  height: 29px;
  padding: 0px 20px 0px 20px;
}

.contact-picture>span {
  width: 100px;
  height: 19px;
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  margin-right: 27px;
  line-height: 20px;
}

.whiteWord {
  color: #ffffff;
}

.contact-phone {
  padding-right: 15px;
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #666666;
}

.contact-QRcode>img {
  display: block;
  margin: 10px auto;
  width: 180px;
  height: 180px;
}

.line {
  width: 230px;
  height: 2px;
  background: #63BEE6;
}

.contact-customer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.contact-customer>img {
  width: 29px;
  height: 30px;
  padding: 0px 20px 0px 20px;
}

.contact-customer>span {
  width: 100px;
  height: 19px;
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  margin-right: 27px;
  line-height: 20px;
}

.contact-qq {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.contact-qq>img {
  width: 28px;
  height: 33px;
  padding: 0px 20px 0px 20px;
}

.contact-qq>span {
  width: 100px;
  height: 19px;
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  margin-right: 27px;
  line-height: 20px;
}
</style>

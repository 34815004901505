<template>
  <div class="footerAll">
    <div class="botCont">
      <div class="botLeft">
        <!-- <ul class="botUl">
          <li class="botLi" v-for="(item, index) in showData" :key="index"
            :style="{ marginRight: index === (showData.length - 1) ? '0px' : '100px' }">
            <div class="title">{{ item.title }}</div>
            <p @click="skipChangeTop(index, index2)" :class="{ pointer: index !== 4 }"
              v-for="(item2, index2) in item.content" :key="index2">
              {{ item2 }}</p>
          </li>
        </ul> -->
        <div class="container">
          <div class="header">
            <span>{{ $t('introduce.Company') }}</span>
            <span>{{ $t('introduce.Products') }}</span>
            <span>{{ $t('introduce.solution') }}</span>
            <span>{{ $t('introduce.Support') }}</span>
          </div>
          <div class="content">
            <p>
              <span v-for="(item, index) in company" :key="index" @click="onClickCompany(index, item.Title)"
                :class="{ pointer: index !== '' }">
                {{ item.Title }}
              </span>
            </p>
            <p>
              <span v-for="(item, index) in enterprise" :key="index" @click="onClickEnterprise(index, item.Line)"
                :class="{ pointer: index !== '' }">
                {{ item.Line }}
              </span>
            </p>
            <p>
              <span v-for="(item, index) in solution" :key="index" @click="onClickSolution(index, item.Title)"
                :class="{ pointer: index !== '' }">
                {{ item.Title }}
              </span>
            </p>
            <p>
              <span v-for="(item, index) in download" :key="index" @click="onClickDownload(index, item.Name)"
                :class="{ pointer: index !== '' }">
                {{ item.Name }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="botRig">
        <div class="telPhone">
          <!-- <img class="botPhoneIcon" src="../assets/public/botPhoneIcon.png" alt="">-->
          <img class="botPhoneIcon" src="../assets/public/botTelIcon.png" alt="">
          <div class="telCont">
            <p>{{ $t('Customer.liu') }}：16628880837</p>
          </div>
        </div>
        <div class="codeDiv">
          <img class="botQrcodeliang" src="../assets/WhiteQRcode.png" alt="">
          <!--<img class="botQrcodeTao" src="../assets/public/botQrcodeTao.png" alt="">-->
        </div>
      </div>
    </div>
    <div class="footerBot">
      <!-- <img class="botWord" src="../assets/public/botWord.png" alt=""> -->
      <p class="botWord_Text">2013 {{ $t('head.companyName') }}<span v-if="$i18n.locale == 'zh'"> | 沪ICP备12042190号</span></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { companyContent, productLine, solutionList, AttributeContent } from '../api'
export default {
  props: ["menuType", "clickIndex", "shortMenuArr", "longMenuArr", 'contactMenuArr', 'supportMenuArr'],
  name: "publicBottom",
  data() {
    return {
      company: [],
      enterprise: [],
      solution: [],
      download: [],
      // showData: [
      //   { title: "公司介绍", content: ["企业介绍", "销售网络", "公司资质", "生产设备",] },
      //   { title: "产品中心", content: ["工业洗衣机", "烘干机", "熨平机", "折叠机", "送布机", "泡洗烘一体机"] },
      //   { title: "解决方案", content: ["洗衣店", "医院", "酒店", "洗衣厂"] },
      //   { title: "支持与下载", content: ["产品资料", "操作视频", "说明书"] },
      // ]
      // showData: [
      //   { title: "产品中心", content: ["洗衣机", "烘干机", "熨平机", "折叠机", "干洗机", "其他"] },
      //   { title: "行业解决方案", content: ["洗衣店", "医院", "酒店", "洗衣厂"] },
      //   { title: "关于赛航", content: ["赛航简介", "商务合作", "销售网络", "公司资质", "生产设备", "版权信息"] },
      //   { title: "赛航优势", content: ["制造优势", "技术创新", "服务优势"] },
      // ]
    }
  },
  computed: {
    ...mapState({
      solveBottom: state => state.solveBottom,
      productsBottom: state => state.productsBottom,
      introduceBottom: state => state.introduceBottom,
      supportBottom: state => state.supportBottom,
      locale: (state) => state.locale,
    })
  },
  watch: {
    locale: {
      handler() {
        // 公司介绍
        let params = {}
        companyContent(params).then(res => {
          // console.log(res)
          this.company = res.Content
        })
        // 产品中心
        let params1 = {};
        productLine(params1).then((res) => {
          // console.log(res);
          this.enterprise = res.Line;
        });
        // 解决方案
        let params2 = {};
        solutionList(params2).then((res) => {
          // console.log(res);
          this.solution = res.Solution;
        });
        // 支持与下载
        let params3 = {};
        AttributeContent(params3).then((res) => {
          // console.log(res);
          this.download = res.SuptCate;
        });
      }
    }
  },
  created() {
    // console.log(this.$route);
    // 公司介绍
    let params = {}
    companyContent(params).then(res => {
      // console.log(res)
      this.company = res.Content
    })
    // 产品中心
    let params1 = {};
    productLine(params1).then((res) => {
      // console.log(res);
      this.enterprise = res.Line;
    });
    // 解决方案
    let params2 = {};
    solutionList(params2).then((res) => {
      // console.log(res);
      this.solution = res.Solution;
    });
    // 支持与下载
    let params3 = {};
    AttributeContent(params3).then((res) => {
      // console.log(res);
      this.download = res.SuptCate;
    });
  },
  methods: {
    // 公司介绍
    onClickCompany(index, title) {
      this.$store.commit("setIntroduceBottom", !this.introduceBottom);
      if (this.$route.name == "introduce") {
        window.scrollTo({ top: 620, behavior: 'smooth' });
        this.$router.push({ path: "/introduce", query: { _type: title, index: index }, })
        this.$store.commit("introduceClick", index);
      } else {
        this.$router.push({ path: "/introduce", query: { _type: title, index: index }, })
      }
      // console.log(index);
      // console.log(title);
    },
    // 产品中心
    onClickEnterprise(index, line) {
      this.$store.commit("setProductsBottom", !this.productsBottom);
      if (this.$route.name == "Products") {
        window.scrollTo({ top: 500, behavior: 'smooth' });
        this.$router.push({ path: "/Products", query: { _type: line, index: index }, });
        this.$store.commit("setProductsLeftClick", index);
      } else {
        this.$router.push({ path: "/Products", query: { _type: line, index: index }, });
      }
      // console.log(index);
      // console.log(line);
    },
    // 解决方案
    onClickSolution(index, title) {
      this.$store.commit("setSolveBottom", !this.solveBottom);
      if (this.$route.name == "solve") {
        window.scrollTo({ top: 500, behavior: 'smooth' });
        this.$router.push({ path: "solve", query: { _type: title, index: index }, });
        this.$store.commit("setSolveLeftClick", index);
      } else {
        this.$router.push({ path: "/solve", query: { _type: title, index: index }, });
      }
      // console.log(index);
      // console.log(title);
    },
    // 支持与下载
    onClickDownload(index, name) {
      this.$store.commit("setSupportBottom", !this.supportBottom);
      if (this.$route.name == "support") {
        window.scrollTo({ top: 500, behavior: 'smooth' });
        this.$router.push({ path: "/support", query: { _type: name, index: index }, });
        this.$store.commit("setsupportClick", index + 1);
      } else {
        this.$router.push({ path: "/support", query: { _type: name, index: index }, });
      }
      // console.log(index);
      // console.log(name);
    },

    // skipChangeTop(index, index2) {
    //   console.log(index, index2)
    //   if (index === 0) {
    //     this.$store.commit("setIntroduceBottom", !this.introduceBottom);
    //     if (this.$route.name === "introduce") {
    //       this.$store.commit("setIntroduceClick", index2);
    //     } else {
    //       this.$router.push({ name: "introduce", params: { _type: index2 } })
    //     }
    //   } else if (index === 1) {
    //     this.$store.commit("setProductsBottom", !this.productsBottom);
    //     if (this.$route.name === "Products") {
    //       this.$store.commit("setProductsLeftClick", (index2 + 1));
    //       // this.$router.push({name:"Products",params:{_type1:index2 + 1}})
    //     } else {
    //       this.$router.push({ name: "Products", params: { _type1: index2 + 1 } })
    //     }
    //   } else if (index === 2) {
    //     this.$store.commit("setSolveBottom", !this.solveBottom);
    //     if (this.$route.name === "solve") {
    //       this.$store.commit("setSolveLeftClick", index2);
    //     } else {
    //       this.$router.push({ name: "solve", params: { _type: index2 } })
    //     }
    //   } else if (index === 3) {
    //     this.$store.commit("setSupportBottom", !this.supportBottom);
    //     if (this.$route.name === "support") {
    //       this.$store.commit("setsupportClick", index2);
    //     } else {
    //       this.$router.push({ name: "support", params: { _type: index2 } })
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>
.footerAll {
  width: 100%;
  height: 570px;
  padding: 100px 0;
  box-sizing: border-box;
  background-color: #56C9FB;
  color: #ffffff;
}

.botCont {
  width: 1087px;
  padding: 0 156.5px;
  margin: 0 auto;
  display: flex;
}

.botLeft {
  width: 600px;
}

.botRig {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.botUl {
  overflow: hidden;
}

.botLi {
  list-style: none;
  text-align: left;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.botLi p {
  height: 13px;
  line-height: 13px;
  margin-bottom: 17px;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #007AC8;
}

.title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 35px;
}

.botPhoneIcon {
  /*width: 314px;*/
  /*height: 60px;*/
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 13px;
}

.telCont {
  float: left;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 30px;
}

.telPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.codeDiv {}

.botQrcodeliang,
.botQrcodeTao {
  width: 180px;
  height: 180px;
  margin-top: 35px;
}

/*.botQrcodeliang{*/
/*    margin-right: 32px;*/
/*}*/
.botRig {
  padding-left: 53px;
  border-left: 1px solid #ffffff;
}

.footerBot {
  width: 100%;
  margin: 0 auto;
  margin-top: 90px;
}

.botWord {
  width: 419px;
  height: 13px;
}

.titleP:hover {
  color: #007AC8;
}

.botWord_Text {
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.botTitle {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
}

.botTitle>span {
  margin-right: 100px;
}

.botcenter {
  display: flex;
  flex-direction: column;
}

.botCompany {
  width: 100px;
  display: flex;
  padding-top: 10px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.header span {
  flex: 1;
  text-align: center;
  padding: 10px;
  margin: 0;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content p {
  flex: 1;
  padding-left: 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 12px;
}

.content p span {
  padding-bottom: 10px;
}
</style>



import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n/index'
import $ from './jq/jquery.js'
Vue.prototype.$ = $;
Vue.config.productionTip = false

const app=new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
export default app

import Vue from 'vue'
import VueRouter from 'vue-router'
import introduce from '../views/introduce.vue'
import solve from '../views/solve.vue'
import Index from '../views/Index.vue'
import contact from '../views/contact.vue'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import support from '../views/support.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/introduce',
    name: 'introduce',
    component: introduce
  },
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/solve',
    name: 'solve',
    component: solve
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Products',
    name: 'Products',
    component: Products,
    // props: (route) => ({ showDetails: route.query.showDetails === 'true', id: route.query.id })
  },
  {
    path: '/support',
    name: 'support',
    component: support
  },

]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div v-if="contentData" class="wrapper">
    <div class="allPic">
      <img class="bannerImg" v-show="num === index" v-for="(item, index) in contentData.bPic" :key="index"
        :src="item.PicUrls" alt />
      <!-- <img :class="'bannerImg' + index" v-show="num === index" v-for="(item, index) in bannerPic" :key="index" :src="item" alt /> -->
    </div>
    <div class="content">
      <div class="cap">
        <p class="capHeader">{{ $t('index.IndustrySolutions') }}</p>
        <div class="capLine"></div>
        <p class="capFooter">SOLUTION</p>

        <!--        <div class="consultDiv" v-if="showConsult">-->
        <!--          <img class="serviceMask" src="../assets/index/serviceMask.png" alt="">-->
        <!--          <img class="deleteIcon" src="../assets/index/deleteIcon.png" alt="" @click="closeConsult">-->
        <!--          <img class="later" src="../assets/index/later.png" alt="" @click="closeConsult">-->
        <!--          <div class="consultBtn" @click="closeConsult">-->
        <!--            <img class="consult" src="../assets/index/consult.png" alt="">-->
        <!--            <a class="qqConsult" target="blank" href="http://wpa.qq.com/msgrd?v=3&uin=18916676531&site=qq&menu=yes&from=message&isappinstalled=0"><img border="0" src="http://wpa.qq.com/pa?p=1:18916676531:41" alt="给我发送信息"></a>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="solve">
        <div class="solveDiv" v-for="(item, index) in contentData.Solution" :key="index"
          :style="{ marginRight: index == contentData.Solution.length - 1 ? '0px' : '21px' }"
          @click="solution(item.Title)">
          <div class="solveDivImg">
            <img :src="item.Headimg" alt />
          </div>
          <div class="solveDivText">{{ item.Title }}</div>
        </div>
      </div>
      <div class="cap">
        <p class="capHeader">{{ $t('product.ProductCenter') }}</p>
        <div class="capLine"></div>
        <p class="capFooter">PRODUCTS CENTER</p>
      </div>
      <div class="product">
        <div class="productDiv" v-for="(item, index) in contentData.Product" :key="index">
          <div class="productDivImg">
            <img class="productImg" :src="item.Headimg" alt />
          </div>
          <div class="productTitle">{{ item.Line }}</div>
          <!-- <div class="productType">{{ item.Model }}</div> -->
          <div class="productLine"></div>
          <div class="productText">{{ item.Outline }}</div>
          <!-- <div class="productText">{{ item.footer2 }}</div>
          <div class="productText">{{ item.footer3 }}</div> -->
          <div class="productDetail" @click="lookMoreLine(item.Line)">{{ $t('index.MoreProducts') }}</div>
        </div>
      </div>
      <div class="cap">
        <p class="capHeader">{{ $t('index.NewsCenter') }}</p>
        <div class="capLine"></div>
        <p class="capFooter">NEWS</p>
      </div>
      <div class="news-content">
        <div class="news-img">
          <img src="../assets/news.png" />
          <p class="company-title">{{ $t('head.companyName') }}</p>
        </div>
        <div class="news-topic">
          <div class="news-box" v-for="(item, index) in contentData.News" :key="index" @click="jumpPage(index)">
            <div class="news-title-box">
              <p class="news-title">{{ item.Title }}</p>
              <div class="news-subhead">{{ item.Outline }}</div>
            </div>
            <div class="news-date-box">
              <div>
                <p class="news-date-day">{{ item.day }}</p>
                <p class="news-date-text">{{ item.PostDate }}</p>
              </div>
              <!-- <img class="news-more1" src="@/assets/index/more.png" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footerDiv">
        <div class="footerDivA">
          <div class="footerDivATitle">{{ contentData.Content[0].Title }}</div>
          <div class="footerDivACenter">
            <div class="footerDivALine"></div>
          </div>
          <div class="footerDivABtm">ABOUT</div>
          <div class="footerDivAText">{{ contentData.Content[0].Outline }}</div>
          <div class="footerDivDetail" @click="changePage">{{ $t('index.LearnMore') }}</div>
        </div>
        <div class="footerDivB">
          <img :src="contentData.Content[0].Headimg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { homeContent, AttributeContent } from "@/api";
import moment from 'moment';

export default {
  name: "index",
  data() {
    return {
      num: 0,
      clientWidth: 0,
      contentData: null, //首页数据
    };
  },
  computed: {
    ...mapState({
      showConsult: (state) => state.showConsult,
      locale: (state) => state.locale,
    }),
  },
  watch: {
    locale: {
      handler() {
        this.getPageContent()
      }
    }
  },
  created() {
    //获取页面内容
    this.getPageContent();
  },
  mounted() {
    this.$store.commit("setChangeLiBackGround", 1)
    this.clientWidth = document.body.clientWidth;
    window.scrollTo(0, 0);
    this.$store.commit("setChangeLiBackGround", 1);
    var timer = setInterval(this.bannerAni, 3000);
  },
  methods: {
    //获取页面内容
    getPageContent() {
      let params = {};
      homeContent(params).then((res) => {
        if (res.News.length > 4) {
          res.News = res.News.slice(0, 4);
        }
        this.contentData = res;
        console.log(res);
        for (let i = 0; i < res.News.length; i++) {
          // console.log(res.News[i].PostDate);
          var date = new Date(res.News[i].PostDate);
          var year = date.getFullYear(); // 获取年份，返回2014
          var month = date.getMonth() + 1; // 获取月份，返回12（注意要加1，因为月份是从0开始计数）
          var formattedDate = year + "/" + month; // 返回"2014/12"
          var day = date.getDate(); // 获取日期，返回10

          // 修改item.PostDate和item.day的值
          res.News[i].PostDate = formattedDate;
          res.News[i].day = day;
          // console.log(formattedDate);
          // console.log(day);

          // 格式化日期并赋值给item.PostDate
          // this.contentData.News.forEach((item) => {
          //   // 使用moment.js或其他日期处理库来格式化日期
          //   const formattedDate = moment(item.PostDate).format("YYYY/MM/DD");
          //   const day = moment(item.PostDate).format("dddd");

          //   // 将格式化后的日期和星期赋值给item.PostDate
          //   item.PostDate = formattedDate;
          //   item.day = day;
          // });
        }
      });
    },
    //咨询弹框
    closeConsult() {
      this.$store.commit("setShowConsult", false);
    },
    // 了解更多
    changePage() {
      this.$router.push({ name: "introduce" });
    },
    // 解决方案
    solution(title) {
      console.log(title);
      this.$router.push({
        path: "solve",
        query: { _type: title },
      });
      this.$store.commit("setChangeLiBackGround", 4);
    },
    // 更多产品
    lookMoreLine(line) {
      this.$router.push({
        path: "/Products",
        query: { _type: line },
      });
    },
    // 轮播
    bannerAni() {
      this.num++;
      if (this.num > this.contentData.bPic.length - 1) {
        this.num = 0;
      }
    },
    // 跳转新闻资讯
    jumpPage(index) {
      let params = {};
      homeContent(params).then((res) => {
        // console.log(res);
        let newsId = res.News[index].NewsID
        this.$router.push({
          path: "contact",
          query: { id: newsId, showDetails: false },
        });
        this.$store.commit("setChangeLiBackGround", 5);
      })

      // let data = {  };
      // AttributeContent(data).then((res) => {
      //   console.log(res.NewsCate);
      //   let newsName = res.NewsCate
      //   this.$router.push({
      //     path: "contact",
      //     query: { _data: newsName },
      //   });
      // })
    },
  },
};
</script>

<style scoped>
/* .wrapper {
  background-color: #ccc;
} */
.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #00ff33;
  /* 两种都可以 */
}

.swiper {
  width: 100%;
  height: 600px;
  background-color: pink;
}

.content {
  width: 1200px;
  /* height: 2550px; */
  margin: auto;
  background-color: #fff;
}

.cap {
  width: 1200px;
  height: 335px;
  /* background-color: pink; */
  box-sizing: border-box;
  padding-top: 120px;
  padding-bottom: 100px;
  font-weight: bold;
}

.capHeader {
  width: 500px;
  /* height: 20px; */
  margin: auto;
  /* background-color: yellow; */
  text-align: center;
  font-size: 30px;
  color: #0097d8;
}

.capLine {
  width: 77px;
  height: 4px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #ffd02c;
}

.capFooter {
  width: 500px;
  /* height: 20px; */
  margin: auto;
  /* background-color: aqua; */
  text-align: center;
  font-size: 20px;
  color: #333333;
}

.solve {
  width: 1200px;
  height: 344px;
  margin: auto;
  /* background-color: pink; */
}

.solveDiv {
  width: 284px;
  height: 344px;
  margin-right: 21px;
  float: left;
  background-color: #f7f9fc;
  cursor: pointer;
}

.solveDivImg {
  width: 284px;
  height: 284px;
}

.solveDivImg>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.solveDivText {
  width: 200px;
  text-align: center;
  margin: auto;
  font-size: 14px;
  color: #333;
  margin-top: 15px;
}

.product {
  width: 1200px;
  /* height: 1384px; */
  /* background-color: green; */
  display: grid;
  grid-template-columns: repeat(4, 284px);
  grid-gap: 20px;
}

.productDiv {
  width: 280px;
  /* height: 590px; */
  background-color: white;
  /* position: relative; */
  /* float: left; */
  /* margin-bottom: 200px; */
}

.productDivImg {
  width: 280px;
  height: 350px;
  margin-bottom: 24px;
  background-color: #f7f9fc;
  position: relative;
}

.productTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  /* background-color: greenyellow; */
}

.productType {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #333;
  /* background-color: yellow; */
}

.productLine {
  width: 36px;
  height: 2px;
  margin-bottom: 18px;
  background-color: #0097d8;
}

.productText {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
}

.productDetail {
  width: 120px;
  height: 36px;
  background-color: #0097d8;
  color: #fff;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  cursor: pointer;
}

.footer {
  width: 100%;
  height: 680px;
  background-color: #f7f9fc;
}

.footerDiv {
  width: 1200px;
  height: 680px;
  margin: auto;
  /* background-color: pink; */
  position: relative;
}

.footerDivA {
  width: 610px;
  height: 560px;
  padding-top: 100px;
  box-sizing: border-box;
  /* background-color: yellow; */
}

.footerDivATitle {
  width: 520px;
  font-size: 30px;
  text-align: center;
  /* background-color: pink; */
}

.footerDivACenter {
  width: 520px;
  align-items: center;
}

.footerDivALine {
  width: 77px;
  height: 8px;
  margin: auto;
  margin-top: 17px;
  margin-bottom: 17px;
  background-color: #ffd02c;
}

.footerDivABtm {
  width: 520px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
}

.footerDivAText {
  width: 525px;
  color: #000;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 60px;
}

.footerDivDetail {
  width: 140px;
  height: 40px;
  color: #fff;
  background-color: #0097d8;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.footerDivB {
  width: 590px;
  height: 700px;
  background-color: #f7f9fc;
  position: absolute;
  right: 0;
  bottom: 120px;
}

.footerDivB>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 180px; */
  /* margin-left: 50px; */
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
}

.allPic {
  min-width: 1400px;
  position: relative;
  height: 616px;
}

.allPic img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ani 2s;
}

.bannerImg {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.bannerImg0 {
  height: 616px;
}

.bannerImg1 {
  height: 600px;
}

.bannerImg2 {
  height: 600px;
}

@keyframes ani {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.consultDiv {
  width: 401px;
  height: 174px;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.serviceMask {
  width: 401px;
  height: 174px;
}

.deleteIcon {
  width: 15px;
  height: 14px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.later {
  width: 94px;
  height: 36px;
  position: absolute;
  bottom: 21px;
  right: 150px;
  cursor: pointer;
}

.consultBtn {
  width: 94px;
  height: 36px;
  position: absolute;
  bottom: 21px;
  right: 30px;
  cursor: pointer;
}

.consult {
  width: 94px;
  height: 36px;
}

.qqConsult {
  width: 94px;
  height: 36px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0;
}

.news-content {
  margin-bottom: 200px;
  display: flex;
}

.news-img {
  position: relative;
  width: 400px;
  height: 500px;
  box-shadow: 0px 0px 20px 0px rgba(182, 188, 204, 0.8);
}

.news-img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  color: white;
}

.news-topic {
  width: 780px;
  display: grid;
  grid-template-columns: repeat(2, 380px);
  height: 500px;
  grid-gap: 20px;
  margin-left: 20px;
}

.news-box {
  width: 380px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #F9FBFC;
}

.news-title-box {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.news-title {
  font-size: 16px;
  font-weight: bold;
}

.news-subhead {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgb(82, 82, 82);
}

.news-date-box {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news-date-day {
  font-size: 20px;
  font-weight: bold;
}

.news-date-text {
  font-size: 12px;
  color: rgb(167, 166, 166);
}

.news-more1 {
  width: 40px;
  height: 40px;
}
</style>

export default {
  "lang": "中文",
  "introduce": {
    "Company": "公司介绍",
    "Products": "产品中心",
    "solution": "解决方案",
    "Support": "支持与下载",
    'AboutGamesail':'关于赛航'
  },
  "support": {
    "Home": "首页",
    "Support": "支持与下载",
    'Search': '搜索',
    "Category": "类别",
    "Title": "标题",
    "FileType": "文件类型",
    "FileSize": "文件大小",
    "ReleaseDate": "发布日期",
    "Controls": "操作",
    "Downloads": "下载",
    'QueryName':'查询名称',
    'AllVideos':'所有视频'
  },
  "Customer": {
    "CustomerCenter": "客服中心",
    "Telephone": "电话咨询",
    "OnlineService": "在线客服",
    "WelcomeConsultation": "欢迎您的咨询！",
    'liu':'刘',
  },
  "solve":{
    'solve':'解决方案',
    'ProjectDescription':'项目说明'
  },
  "product":{
    'ProductCenter':'产品中心',
    'ViewDetails':'查看详情',
    'ProductDetails':'产品详情',
    'ServiceSupport':'服务与支持',
    
  },
  "index":{
    'IndustrySolutions':'行业解决方案',
    'MoreProducts':'更多产品',
    'NewsCenter':'新闻中心',
    'LearnMore':'了解更多'
  },
  'contact':{
    'NewsInformation':'新闻资讯',
    'Detail':'详情',
    'Date':'日期'
  },
  'head':{
    'Wechat':'微信公众号',
    'ProductName':'产品名称',
    'EnterProductName':'请输入产品名称',
    'AllNews':'所有新闻',
    'companyName':'上海赛航洗涤设备有限公司',
    'timeOut':'网络请求超时'
  }
}

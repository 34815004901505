import { render, staticRenderFns } from "./support.vue?vue&type=template&id=9ec3ac5c&scoped=true"
import script from "./support.vue?vue&type=script&lang=js"
export * from "./support.vue?vue&type=script&lang=js"
import style1 from "./support.vue?vue&type=style&index=1&id=9ec3ac5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ec3ac5c",
  null
  
)

export default component.exports
<template>
  <div class="all">
    <div class="headTop">
      <ul class="ulTop">
        <li class="li1">
          <img class="logo" src="../assets/public/logo2.png" alt="" @click="homepage" />
        </li>
        <li class="li2">
          <img class="companyName" src="../assets/public/companyName.png" alt="" @click="homepage" />
        </li>
        <li class="li3">
          <img class="phoneIcon" src="../assets/public/telIcon.png" alt="" />
          <p class="phoneCont">{{ $t('Customer.liu') }}：16628880837</p>
          <!-- 梁经理：18916676531 -->
        </li>
        <!-- <li class="li4">
          <img class="qqIcon" src="../assets/public/topQQicon.png" alt="" />
          <p class="phoneCont">QQ：468100401</p>
        </li> -->
        <li class="li5">
          <img class="qrcodeliang" src="../assets/qrcode_258.jpg" alt="" />
          <p class="li5_Text">{{ $t('head.Wechat') }}</p>
          <!--                    <img class="qrcodeTao" src="../assets/public/qrcodeTao.jpg" alt="">-->
        </li>
      </ul>
      <div class="langSwitch">
        <span :class="['switch-item', switchIndex == 'zh' ? 'switch-active1' : '']"
          @click="langSwitchHandle('zh')">简体中文</span>
        <span :class="['switch-item', switchIndex == 'en' ? 'switch-active2' : '']"
          @click="langSwitchHandle('en')">English</span>
      </div>
    </div>
    <div class="headMenu">
      <ul class="menuUl">
        <li v-for="(item, index) in liArr" @click="clickLiFn(index)" :class="{ active: clickLi === index + 1 }"
          class="hoverChange" :key="index">{{ item.title }}</li>
        <li class="inputLi">
          <input type="text" class="searchInput" v-model="filterProductHeader" :placeholder="$t('head.ProductName')" />
          <img class="searchIcon" src="../assets/public/searchIcon.png" alt=""
            @click="ProductQuery(filterProductHeader, filterProduct)">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { productList, productLine } from "@/api";
export default {
  props: ["skipIndex"],
  name: "publicHeader",
  data() {
    return {
      switchIndex: 'zh',
      clickLi: 1,
      pagePath: ["", "introduce", "", "solve", ""],
      filterProductHeader: "",
      filterProduct: [],
      leftProduct: [],
      liArr: [
        { name: "Index", title: "首页", index: 1 },
        { name: "introduce", title: "公司介绍", index: 2 },
        { name: "Products", title: "产品中心", index: 3 },
        { name: "solve", title: "解决方案", index: 4 },
        { name: "contact", title: "新闻资讯", index: 5 },
        { name: "support", title: "支持与下载", index: 6 },
        // {name:"contact",title:"联系我们",index:5},
      ],
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
    })
  },
  watch: {
    locale: {
      handler() {
        const Lang = sessionStorage.getItem('Lang')
        this.switchIndex = Lang ? Lang : 'zh'
        let _type = this.$route.params._type;
        // console.log(this.$route);
        // 侧边栏接口
        let data = {}
        productLine(data).then((res) => {
          // console.log(res);
          for (let i = 0; i < 8; i++) {
            this.leftProduct[i] = res.Line[i];
          }
          this.getProductList(_type);
          for (var i = 0; i < this.liArr.length; i++) {
            if (this.$route.name === this.liArr[i].name) {
              this.clickLi = this.liArr[i].index;
            }
          }
        })
      }
    },
    skipIndex(val) {
      this.clickLi = val;
    },
  },
  created() {
    const Lang = sessionStorage.getItem('Lang')
    this.switchIndex = Lang ? Lang : 'zh'
    // console.log(this.$route);
  },
  mounted() {
    let _type = this.$route.params._type;

    this.liArr[0].title = this.$t('support.Home')
    this.liArr[1].title = this.$t('introduce.Company')
    this.liArr[2].title = this.$t('introduce.Products')
    this.liArr[3].title = this.$t('introduce.solution')
    this.liArr[4].title = this.$t('contact.NewsInformation')
    this.liArr[5].title = this.$t('introduce.Support')
    //获取产品线数据
    this.getProductLine(_type);

    for (var i = 0; i < this.liArr.length; i++) {
      if (this.$route.name === this.liArr[i].name) {
        this.clickLi = this.liArr[i].index;
      }
    }
  },
  methods: {
    langSwitchHandle(switchIndex) {
      this.switchIndex = switchIndex
      sessionStorage.setItem('Lang', switchIndex)
      this.$store.commit("setLocale", switchIndex);
      this.$i18n.locale = switchIndex
      this.liArr[0].title = this.$t('support.Home')
      this.liArr[1].title = this.$t('introduce.Company')
      this.liArr[2].title = this.$t('introduce.Products')
      this.liArr[3].title = this.$t('introduce.solution')
      this.liArr[4].title = this.$t('contact.NewsInformation')
      this.liArr[5].title = this.$t('introduce.Support')


    },
    // 返回首页
    homepage() {
      if (this.$route.fullPath != '/') {
        this.$router.push({
          name: 'Index',
        })
        this.$store.commit("setChangeLiBackGround", 1);
      }
    },
    clickLiFn(index) {
      this.$store.commit("setProductsLeftClick", 0);
      this.$store.commit("setSolveLeftClick", null);
      if (this.clickLi === index + 1) {
        // location.reload();
        // this.$router.push('/'); 
        return;
      }
      this.clickLi = index + 1;
      // console.log(index);
      if (this.liArr[index].name) {
        this.$router.push({ name: this.liArr[index].name });
      }
      this.$emit("activeType", index);
    },

    //获取产品线数据
    getProductLine(_type) {
      let params = {};
      productLine(params).then((res) => {
        // console.log(res);
        for (let i = 0; i < 8; i++) {
          this.leftProduct[i] = res.Line[i];
        }
        this.longMenuArr = res.Line;
        if (_type) this.clickIndex = res.Line.map((item) => item.Line).indexOf(_type);
        this.getProductList(_type || res.Line[0].Line);
        // console.log(res.Line.index)
      });
    },
    //获取产品列表
    getProductList(Line) {
      productList({}).then((res) => {
        // console.log(res);
        this.product = res.Product;
        this.filterProduct = res.Product;
      });
    },
    //查询
    ProductQuery(filterProductHeader, filterProduct) {

      console.log(filterProductHeader)
      //console.log(filterProduct)
      if (this.filterProductHeader == '') {
        alert(this.$t('head.EnterProductName'))
        return
      }
      if (this.$route.fullPath != '/Products') { // 查询 跳转 指定页面
        for (let i = 0; i < filterProduct.length; i++) {
          // console.log(filterProduct[i]);
          let productId = filterProduct[i].ProductID;
          let Line = filterProduct[i].Line;
          if (filterProduct[i].Product == filterProductHeader) {
            // let productId = filterProduct[i].ProductID;
            // let Line = filterProduct[i].Line;
            // 读取输入框的值 匹配
            for (let i = 0; i < 8; i++) {
              if (Line == this.leftProduct[i].Line) {
                // this.$store.commit("setProductsLeftClick", i);
                console.log(i)
                break;
              }
            }
            console.log(productId);
            console.log(Line);
            this.$router.push({
              name: 'Products',
              params: { data: productId, showDetails: false },
            })
            this.$store.commit("setChangeLiBackGround", 3);
            // 模糊查询
          } else if (filterProduct[i].Product.includes(filterProductHeader)) {
            this.$router.push({
              name: 'Products',
              params: { data: productId, showDetails: false },
            })
            this.$store.commit("setChangeLiBackGround", 3);
          }
        }
      } else {
        console.log('在当前页面')
      }
    },
  },
};
</script>

<style scoped>
.qiniu{
  cursor: pointer;
}

.langSwitch {
  width: 150px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.switch-item {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.switch-active1 {
  width: 50%;
  height: 100%;
  background-color: #0097d8;
  color: #fff;
  border-radius: 30px 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-active2 {
  width: 50%;
  height: 100%;
  background-color: #0097d8;
  color: #fff;
  border-radius: 0 30px 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all {
  width: 100%;
  height: 180px;
}

.headTop {
  width: 1400px;
  height: 120px;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  overflow: hidden;
}

li {
  list-style: none;
  float: left;
}

.li1,
.li2,
.li3,
.li4 {
  margin-top: 15px;
}

.li5 {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: block;
  flex-wrap: wrap;
  align-content: space-between;
  */
}

.li5_Text {
  font-size: 3px;
}

.li2 {
  margin-left: 15px;
  margin-right: 110px;
}

.li4 {
  margin-left: 29px;
  margin-right: 29px;
}

.logo {
  width: 290px;
  height: 47px;
  cursor: pointer;
}

.companyName {
  width: 367px;
  height: 51px;
  cursor: pointer;
}

.li3,
.li4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.phoneIcon {
  width: 29px;
  height: 43px;
  margin-right: 10px;
}

.phoneCont {
  font-size: 16px;
  color: #666666;
}

.qqIcon {
  width: 36px;
  height: 43px;
  margin-right: 10px;
}

.telCont,
.qqCont {
  float: left;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 21px;
}

.qrcodeTao,
.qrcodeliang {
  width: 80px;
  height: 80px;
}

.qrcodeTao {
  margin-left: 33px;
}

.headMenu {
  width: 100%;
  background-color: #0097d8;
  overflow: hidden;
}

.menuUl {
  width: 1200px;
  padding: 0 100px;
  margin: 0 auto;
}

.headMenu li {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  width: 160px;
  /*padding: 0 56px;*/
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.inputLi {
  width: 200px !important;
  height: 40px;
  position: relative;
  margin-top: 10px;
  float: right;
  background-color: #ffffff;
}

.searchInput {
  width: 160px;
  height: 40px;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
}

.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 14px;
}

.active {
  background-color: #007ac8;
}

.hoverChange:hover {
  background-color: #007ac8;
}
</style>

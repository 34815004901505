<template>
    <div class="pageAll" v-if="solveList.length">
        <div class="contAll">
            <div class="bannerDiv">
                <img class="banner" src="../assets/solveTopIMG.jpg" alt="">
            </div>
            <div class="menu">
                <div class="menuCont"><a href="" style="text-decoration: none; color: inherit;">{{ $t('support.Home') }}</a>
                    >
                    {{ $t('solve.solve') }}{{ clickNum ==
                        null ? '' : ' > ' + solveList[clickNum].Title }}</div>
            </div>

            <div class="content" v-if="detailsData">
                <div class="left" ref="box">
                    <leftMenu menuType="short" :shortMenuArr="solveList" :clickIndex="clickNum" @activeType="enterDetails">
                    </leftMenu>
                </div>
                <!-- <div class="right" v-if="clickNum == null">
                    <img class="contImg" @click="skipDetails(index)" v-for="(item, index) in showData" :key="index"
                        :src="item" :style="{ marginRight: (index + 1) % 2 === 0 ? '0' : '20px' }" alt="">
                </div> -->
                <div class="right">
                    <div class="title">{{ detailsData.Title }}</div>
                    <div class="project">
                        <!-- <div class="project-title">项目说明</div>
                        <div class="line"></div> -->
                        <div class="project-cont">
                            <div class="rigWord">
                                <div class="rigWord-title">{{ $t('solve.ProjectDescription') }}</div>
                                <div class="shortLine"></div>
                                <p>{{ detailsData.Outline }}</p>
                                <!-- <p v-for="item in detailsData[clickNum].explain">{{item}}</p> -->
                            </div>
                            <div class="smallDiv">
                                <video v-if="detailsData.VideoUrls" controls :poster="detailsData.VideoUrls"
                                    :src="detailsData.VideoUrls"></video>
                                <img class="smallImg" :src="detailsData.PicUrls" alt="" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="solveWay">
                        <div class="project-title">{{ $t('solve.solve') }}</div>
                        <div class="line"></div>
                        <div class="wordCont" v-html="detailsData.Contents"></div>
                    </div>
                    <!-- <div class="layout">
                        <div class="project-title">方案布局</div>
                        <div class="line"></div>
                        <div class="layoutDiv">
                            <img :class="'layoutImg'+clickNum" :src="detailsData[clickNum].layoutImg" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import leftMenu from '@/components/leftMenu.vue'
import { solutionList, solutionInfo } from "@/api";
export default {
    name: "solve",
    components: {
        leftMenu
    },
    data() {
        return {
            elementOffsetTop: 0,//元素到顶部的距离
            clickNum: 0,
            // showData: [require("../assets/solve/contImg1.png"), require("../assets/solve/contImg2.png"), require("../assets/solve/contImg3.png"), require("../assets/solve/contImg4.png")],
            solveList: [],
            detailsData: null,
            clickIndex: 0,
            dataNum: '0',
        }
    },
    computed: {
        ...mapState({
            botClickIndex: state => state.solveLeftClick,
            solveBottom: state => state.solveBottom,
            locale: (state) => state.locale,

        })
    },
    watch: {
        solveBottom(val) {
            if (this.$refs.box) {
                this.elementOffsetTop = this.$refs.box.offsetTop;
                this.$('html,body').animate({ scrollTop: this.elementOffsetTop }, 500);
                this.clickNum = this.botClickIndex;
            }
        },
        // 监听点击底部后显示相应内容
        botClickIndex: {
            deep: true,
            handler(newValue) {
                this.clickIndex = newValue
                this.getSolutionInfo(this.solveList[this.clickNum].SolutionID)
            }
        },
        locale: {
            handler() {
                //获取解决方案列表
                this.getSolutionList()
            }
        }

    },
    created() {
        window.scrollTo(0, 0);

        // 接收首页名称
        let data = this.$route.query._type;

        let index = this.$route.query.index;
        this.clickIndex = index || 0

        //获取解决方案列表
        this.getSolutionList(data)
    },
    mounted() {
        this.$store.commit("setChangeLiBackGround", 4)
        if (this.$refs.box) {
            this.elementOffsetTop = this.$refs.box.offsetTop;
            this.$store.commit("setChangeLiBackGround", 4);

            // if (this.$route.params._type === 0 || this.$route.params._type) {
            //     this.clickNum = this.$route.params._type;
            //     window.scrollTo(0, this.elementOffsetTop);
            // } else {
            //     window.scrollTo(0, 0);
            // }
        }
    },
    methods: {
        //获取解决方案列表
        getSolutionList(data) {
            // console.log(data);
            let params = {}
            solutionList(params).then(res => {
                this.solveList = res.Solution
                if (data) {
                    this.clickNum = res.Solution.map((item) => item.Title).indexOf(data)
                } else {
                    this.$router.replace({
                        query: { _type: res.Solution[this.clickNum].Title }
                    })
                }
                if (this.clickNum == -1) this.clickNum = 0
                this.getSolutionInfo(res.Solution[this.clickNum].SolutionID)

            })

        },
        getSolutionInfo(ID) {
            let params = { ID }
            solutionInfo(params).then(res => {
                // console.log(res);
                var div = document.createElement('div');
                div.innerHTML = res.Solution[0].Contents
                res.Solution[0].Contents = div.textContent || div.innerText
                this.detailsData = res.Solution[0]
            })
        },
        skipDetails(index) {//点击图片进入详情
            this.clickNum = index;
            console.log('mm' + index)
        },
        enterDetails(data) {//点击左侧菜单进入详情
            if (data == this.clickNum) { return }
            this.clickNum = data;
            this.getSolutionInfo(this.solveList[this.clickNum].SolutionID)
            this.$router.replace({ query: { _type: this.solveList[this.clickNum].Title } })
        }
    }
}
</script>
<style scoped="scoped">
.bannerDiv {
    min-width: 1400px
}

.banner {
    width: 100%;
    vertical-align: bottom;
}

.menu {
    width: 100%;
    height: 60px;
    background-color: #F7F9FC;
    margin-bottom: 50px
}

.menuCont {
    width: 1200px;
    padding: 0 100px;
    height: 60px;
    line-height: 60px;
    text-align: left;
    margin: 0 auto;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
}

.content {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #ffffff;
}

.left {
    margin-right: 20px;
}

.right {
    width: 915px;
}

.contImg {
    width: 417px;
    height: 503px;
    margin-bottom: 66px;
    cursor: pointer;
}

.title {
    width: 853px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(0, 151, 216, 1);
    border-bottom: 1px solid #0097D8;
    text-align: left;
    padding-bottom: 21px;
    margin-bottom: 50px;
}

.project-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    text-align: left;
}

.line {
    width: 36px;
    height: 3px;
    background: rgba(0, 151, 216, 1);
    border-radius: 1px;
    margin-top: 19px;
    margin-bottom: 29px;
    text-align: left;
}

.project-cont {
    display: flex;
    justify-content: space-between;
}

.rigWord {
    width: 600px;
    white-space: pre-wrap;
}

.smallImg {
    width: 320px;
    height: 240px;
    object-fit: cover;
}

.smallImg0 {
    width: 216px;
    height: 182px;
}

.smallImg1 {
    width: 187px;
    height: 187px;
}

.smallImg2 {
    width: 185px;
    height: 185px;
}

.smallImg3 {
    width: 187px;
    height: 202px;
}

.rigWord-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    text-align: left;
}

.shortLine {
    width: 36px;
    height: 3px;
    background: rgba(0, 151, 216, 1);
    border-radius: 1px;
    margin-top: 19px;
    margin-bottom: 29px;
    text-align: left;
}

.rigWord p {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 36px;
    text-align: left;
}

.solveWay {
    margin-top: 50px;
}

.wordCont {
    width: 854px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 36px;
    text-align: justify;
    margin-bottom: 55px;
}

.layout {
    margin-top: 120px;
}

.layoutImg0 {
    width: 853px;
    height: 570px;
}

.layoutImg1 {
    width: 848px;
    height: 533px;
}

.layoutImg2 {
    width: 853px;
    height: 226px;
}

.layoutImg3 {
    width: 854px;
    height: 498px;
}
</style>
<template>
  <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
    <swiper-slide>Slide 1</swiper-slide>
    <swiper-slide>Slide 2</swiper-slide>
    <swiper-slide>Slide 3</swiper-slide>
    <swiper-slide>Slide 4</swiper-slide>
    <swiper-slide>Slide 5</swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
export default {
  name: "carrousel",
  data() {
    return {
      swiperOptions: {
        // loop: true,
        // autoplay: true,
        autoplay: {
          delay: 1000, //1秒切换一次
          stopOnLastSlide: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          //使用前进后退按钮来控制Swiper切换
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(3, 1000, false);
  },
  methods: {
    // banner: function () {
    //   setTimeout(() => {
    //     var mySwiper1 = new Swiper(".swiper", {
    //       autoplay: true, //可选选项，自动滑动
    //       // loop: true, //可选选项，开启循环
    //       // resizeReInit: true,
    //       // pagination: ".pagination",
    //     });
    //   }, 100);
    // },
  },
};
</script>